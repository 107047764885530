.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.konvajs-content,
canvas {
    width: 360px !important;
    height: 344.5px !important;
}

.konvajs-content {
    border: 5px solid #594739;
    box-sizing: content-box;
    overflow: hidden;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.card {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    background: #d1c6bd;
    margin-right: 10px;
    opacity: 0.7;
    transition: 200ms;
    border: 3px solid #594739;
}

.card:hover {
    opacity: 1;
}

.card:active {
    transform: scale(0.9);
}

.card.active {
    border-color: #fff;
    opacity: 1;
}

.card > div {
    position: absolute;
    color: #594739;
    font-size: 12px;
    left: 0;
    right: 0;
    height: 20px;
    bottom: 0;
    padding: 2px 5px;
    background: #00000027;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card.none {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card.none img {
    width: 30px;
    height: 30px;
}

.items-list {
    margin-bottom: 20px;
}

.left-side {
    background-color: #c0b0a3;
}

.right-side {
    background-color: #c0b0a3;
}

.items-title {
    text-align: left;
    font-weight: 900;
    text-transform: capitalize;
    color: #594739;
    margin-bottom: 5px;
    font-size: 20px;
}

.react-horizontal-scrolling-menu--arrow-left,
.react-horizontal-scrolling-menu--arrow-right {
    background: #cbc0b5;
    border: 2px solid #594739;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.react-horizontal-scrolling-menu--arrow-left button,
.react-horizontal-scrolling-menu--arrow-right button {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
}

.react-horizontal-scrolling-menu--item:first-child {
    margin-left: 10px;
}

h1 {
    font-size: 50px;
}

.buttons button {
    padding: 15px;
    background: #cbc0b5;
    border: 3px solid #594739;
    width: 100%;
    margin-top: 20px;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttons button svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  stroke-width: 3;
}

.buttons button.download {
    background: #594739;
    color: #cbc0b5;
}

@media screen and (max-width: 840px) {
    .main-site {
        flex-direction: column-reverse;
        align-items: center;
    }
}
